<template>
  <b-modal id="review-files" modal-class="common-modal" hide-footer no-close-on-backdrop no-close-on-esc>
    <template slot="modal-header">
      <h5 class="modal-title">{{ $t('general.addFiles') }}</h5>
      <button v-if="!sendingForm" @click="hideModal()" class="close"></button>
    </template>

    <template slot="default">
      <ValidationObserver ref="form">
        <b-form novalidate class="common-form" autocomplete="off" @submit.prevent="validateBeforeSubmit()">
          <!-- Fields -->
          <fields :form="form" @filesUpdated="onFilesUpdated"></fields>

          <div class="form-actions center">
            <slot v-if="user.is_superuser || user.profile.permissions.includes('review_file_groups')">
              <div>
                <b-button type="submit" variant="primary" :disabled="sendingForm" block size="big" @click="handleSubmit('published')">{{
                  publishedText
                }}</b-button>
              </div>
              <div>
                <b-button variant="danger" :disabled="sendingForm" block size="big" @click="handleSubmit('rejected')">{{
                  rejectText
                }}</b-button>
              </div>
            </slot>
            <slot
              v-else-if="user.profile.permissions.includes('edit_file_groups') && !user.profile.permissions.includes('review_file_groups')"
            >
              <div>
                <b-button type="submit" variant="primary" :disabled="sendingForm" block size="big" @click="handleSubmit('in_review')">{{
                  saveText
                }}</b-button>
              </div>
              <div>
                <b-button variant="danger" :disabled="sendingForm" block size="big" @click="hideModal()">{{ cancelText }}</b-button>
              </div>
            </slot>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
@import './Review';
</style>

<script>
import bus from '@/api/bus';
import commonSrv from '@/api/common';
import filesSrv from '@/api/files';
import { showError, showSuccess } from '@/components/ScreenAlert';
import i18n from '@/i18n';
import { ValidationObserver } from 'vee-validate';
import { mapGetters } from 'vuex';

export default {
  components: {
    ValidationObserver,
    Fields: () => import('./Fields.vue'),
  },
  props: {
    fileInfo: Object,
  },
  data() {
    return {
      form: {
        project_id: null,
        category_id: null,
        tags: [],
        files: [],
        file_ids: [],
        newFiles: [],
      },
      loadingInfo: null,
      sendingForm: false,
      sendButtonText: i18n.t('general.sendReview'),
      publishedText: i18n.t('general.toPost'),
      rejectText: i18n.t('general.reject'),
      saveText: i18n.t('general.save'),
      cancelText: i18n.t('general.cancel'),
      user: this.$store?.getters?.['auth/user'],
    };
  },

  created() {
    this.init();
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  methods: {
    init() {
      this.from = {};
      const tags = [];
      this.form = commonSrv.cloneObject(this.fileInfo);
      if (this.form.tags) {
        this.form.tags.forEach((element) => {
          tags.push(element.description);
        });
        this.form.tags = tags;
      }

      this.form.file_ids = this.form.files.map((f) => f.id);
      this.form.newFiles = [];
    },
    onFilesUpdated(files) {
      if (files.dataList) this.form.file_ids = files.dataList.map((f) => f.id).filter((f) => f !== undefined);
      if (files.new) this.form.newFiles = files.new;
    },

    hideModal() {
      this.$emit('closeModal');
      this.resetForm();
    },

    resetForm() {
      this.sendingForm = false;
      this.sendButtonText = i18n.t('general.sendReview');
      this.form = {
        project_id: null,
        category_id: null,
        name: null,
        description: null,
        tags: [],
        files: [],
        file_ids: [],
        newFiles: [],
      };
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },

    validateBeforeSubmit() {
      return commonSrv.validateForm(this.$refs.form, this.handleSubmit);
    },

    handleSubmit(statusData) {
      this.sendButtonText = i18n.t('general.sending');
      this.sendingForm = true;
      const payload = new FormData();
      payload.append('project_id', this.form.project_id);
      payload.append('category_id', this.form.category_id);
      payload.append('name', this.form.name);
      payload.append('description', this.form.description);
      this.form.tags.forEach((tag) => {
        payload.append('tags', tag);
      });
      this.form.file_ids.forEach((fId) => {
        if (fId) payload.append('file_ids', fId);
      });
      this.form.newFiles.forEach((file) => {
        payload.append('files', file);
      });
      if (typeof this.form.preview_file !== 'string') payload.append('preview_file', this.form.preview_file);

      filesSrv
        .updateFileGroupRecord(this.fileInfo.id, payload)
        .then(() => {
          this.updateStatusFile(statusData);
          showSuccess({
            confirmLabel: '',
            title: i18n.t('general.information'),
            content: i18n.t('general.successfulOperation'),
          });
          bus.$emit('getFiles');
          this.hideModal();
          this.beforeDestroy();
        })
        .catch(({ message, error }) => {
          this.hideModal();
          showError({
            confirmLabel: '',
            title: this.$t('general.information'),
            content: message || error || this.$t('general.errorMessage'),
          });
        })
        .finally(() => {
          this.sendingForm = false;
        });
    },
    updateStatusFile(statusData) {
      const data = {
        status: statusData,
      };
      const fileGroupRecordId = this.fileInfo.id;
      this.sendingForm = true;
      filesSrv
        .UpdateStatusFile(fileGroupRecordId, data)
        .then(() => {
          showSuccess({
            confirmLabel: '',
            title: i18n.t('general.information'),
            content: i18n.t('general.successfulOperation'),
          });
          bus.$emit('getFiles');
          this.hideModal();
          this.beforeDestroy();
        })
        .catch(({ message, error }) => {
          this.formResponse = message || error;
        })
        .finally(() => {
          this.sendingForm = false;
        });
    },
  },
};
</script>
